const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_XML2tpC5C",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "4s1glb677jcvnk5fnt8vfsksi4",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "dev.datafresh.com.au",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
      // CSRF
      sameSite: "lax"
    }
  },
  ApiUrl: {
    Rest: "https://api.dev.datafresh.com.au",
    WebSocket: "https://api.dev.datafresh.com.au",
    Url: "https://dev.datafresh.com.au"
  },
  Branding: {
    LoginBackground: {
      "localhost.dev.datafresh.com.au": "/media/bg/datafresh-bg.jpg"
    }
  },
  CognitoInformation: {
    IssuerName: "DataFresh-DEV"
  },
  Stripe: {
    clientKey:
      "pk_test_51MTOn4DWwxi16DmvUavGZs0GHcfCCnv5tvtwX6iis8Xxm2l07tlTIA84ALGWaOJQnPjO0oxhfOd5Im5fvfijZ5yo00F919wKbA"
  }
};

export default config;
